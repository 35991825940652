import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    allowMultiple: {
      type: Boolean,
      default: true
    }
  }

  static outlets = ["frame--choice"]

  selectItem() {
    if (!this.allowMultipleValue) {
      this._deselectAllChoices()
    }
  }

  _deselectAllChoices() {
    (this.childFrameChoiceOutlets || []).forEach(choice => choice.deselect())
  }

  get childFrameChoiceOutlets() {
    return (this.frameChoiceOutlets || []).filter((choice) => {
      return this.element.contains(choice.element)
    })
  }
}
